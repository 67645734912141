import React,{useState,useEffect} from 'react'

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,SimpleGrid,Flex,Text, useColorModeValue,Icon,Button  ,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  ModalCloseButton,FormControl,FormLabel,Input,
} from '@chakra-ui/react'
import { SocialIcon } from 'react-social-icons'
import { MdOutlineEdit } from 'react-icons/md';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { IoIosAddCircle } from 'react-icons/io';
import { useDisclosure } from '@chakra-ui/hooks';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import Card from "components/card/Card";
import { BASE_URL_2 } from 'config';
export default function  Profile ()  {
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )
const iconColor = useColorModeValue("brand.500", "white");
const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure(); // First modal
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure(); // First modal
  const [overlay, setOverlay] = useState(<OverlayOne />)
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [cookies, setCookie, removeCookie] = useCookies(['name','id','type','password', 'email','token','isOffice','phone','phone_01','phone_02','phone_fixe','fall_number','establishment_number','address']);
    const [name,setName]=useState(cookies.name)
    const [password,setPassword]=useState()
    const [phone,setPhone]=useState(cookies.phone)
    const [type,setType]=useState(cookies.phone)
    const [address,setAddresss]=useState(cookies.address)
    const [phone_01,setPhone01]=useState(cookies.phone_01)
    const [phone_02,setPhone02]=useState(cookies.phone_02)
    const [phone_fixe,setPhoneFixe]=useState(cookies.phone_fixe)
    const [fall_number,setFallNumber]=useState(cookies.fall_number)
    const [establishment_number,setEsblishment_number]=useState(cookies.establishment_number)
    const [notes,setNotes]=useState([])
    const [media,setMedia]=useState([])
    const[twiter,setTwiter]=useState('')
    const[snapchat,setSnapchat]=useState('')
    const[facebook,setFacebook]=useState('')
    const[instgram,setInstgram]=useState('')
    const[tiktok,setTikok]=useState('')
    const[whatsapp,setWhatsapp]=useState('')


    useEffect(() => {
     const id_user=cookies.id
     const email=cookies.email
      axios.post(`${BASE_URL_2}/users/note/getAllByUser`,{id_user})
    .then((response) => {
      const result = response.data
      setNotes(result.notes)
    
    })
    axios.post(`${BASE_URL_2}/users/media/get`,{email})
    .then((response) => {
      const result = response.data
      setMedia(result.media)
      setFacebook(result.media.facebook)
       setInstgram(result.media.instgram)
      setWhatsapp(result.media.whatsapp)
      setTikok(result.media.tiktok)
      setTwiter(result.media.twiter)
      setSnapchat(result.media.snapchat) 
    })
    
    }, [])
    
    const notify = (text) => toast.success( text,{position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored", 
    fontFamilly:"Cairo"
  });
  const notify2 = (text) => toast.error( text,{position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored", 
  fontFamilly:"Cairo"
  });
  


      const update = async ()=>{
        const email=cookies.email
       
          axios.post(`${BASE_URL_2}/users/admin/changePassword`,{password,email})
          .then((response) => {
            const result = response.data
            if(result.succes){
            
                onClose1()
                notify("تم تعديل معلومات الحساب بنجاح")
              
              
          
            }
            else{
              onClose1()
              notify2(result.message)
            }
          
          })
        
     
       
      
      
      }
    
  const update2 =async(req,res)=>{
    const email=cookies.email
    axios.post(`${BASE_URL_2}/users/media/update`,{instgram,facebook,twiter,snapchat,tiktok,whatsapp,email})
    .then((response) => {
      axios.post(`${BASE_URL_2}/users/media/get`,{email})
      .then((response) => {
        const result = response.data
      
        setMedia(result.media)
        onClose3()
        notify("تم تعديل معلومات التواصل بنجاح")
        
      })
    })

    
  }
  return (
    <>
       <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <SimpleGrid
      mb='20px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
         <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
     
        <Flex px='25px' justify='space-between' mb='50px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
       معلومات الحساب
        </Text>
        <Button  
       backgroundColor={bgButton}  
        borderRadius='10px'   
        color={iconColor}  
        onClick={()=>{
        setOverlay(<OverlayOne />)
        onOpen1()
        }}
      
        > تعديل  كلمة المرور <Icon as={MdOutlineEdit} width='20px' mr='5px' height='20px' color={iconColor} /></Button>
        </Flex>
        <SimpleGrid columns={{sm:1, md:2}} spacing={10}>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          lineHeight='100%' 
          
        >
        الاسم
        </Text>
        <Text
          color={textColor}
          
          fontWeight='700'
          lineHeight='100%' 
        >
        {cookies.name}
        </Text>
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
        
          
          lineHeight='100%' 
        >
        البريد الالكتروني
        </Text>
        <Text
          color={textColor}
          
          fontWeight='700'
          lineHeight='100%' 
        >
         {cookies.email}
        </Text>
        </Flex>
       
       
        
        </SimpleGrid>
        </Card>



      
        </SimpleGrid>
        </Box>
        <Modal isCentered    isOpen={isOpen1} onClose={onClose1}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader> تعديل  كلمة  المرور </ModalHeader>
         
          <ModalCloseButton />
          <ModalBody>
     
            <FormControl>
              <FormLabel> كلمة المرور</FormLabel>
              <Input  type="password"   onChange={(event)=>{setPassword(event.target.value)}}/>
            </FormControl>
            
           
              
              
            
          </ModalBody>

          
         
      
          <ModalFooter>
            <Button   disabled={password?false:true}  colorScheme='blue' mr={3}  onClick={update}>    تعديل
            </Button> 
           
           
            <Button  onClick={onClose1}>اغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <ToastContainer  position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"/>
    </>
  )
}

