import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components

import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
   
      <h1  style={{fontWeight:'700',fontSize:'30px' ,marginTop:'0px'}} color={logoColor}>Maktabi</h1>
     
    </Flex>
  );
}

export default SidebarBrand;
