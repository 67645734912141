import React,{useState,useEffect} from 'react'

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,SimpleGrid,Flex,Text, useColorModeValue,Icon,Button  ,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  Textarea,
  ModalCloseButton,FormControl,FormLabel,Input,
} from '@chakra-ui/react'
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react'
import ReactLoading from 'react-loading';
import { useCookies } from 'react-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { IoIosAddCircle } from 'react-icons/io';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from "components/card/Card";
import { MdOutlineEdit } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { BiNotepad } from 'react-icons/bi';
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { BASE_URL_2 } from 'config';
export default function Messages  ()  {
const [cookies, setCookie, removeCookie] = useCookies(['name','id', 'email','token','isOffice','phone','phone_01','phone_2','phone_fixe','fall_number','establishment_number','address']);
const [loading,setLoading]=useState(true) 
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )
 const [users,setUsers]=useState([])
 const [replay,setReplay]=useState([])
 const [town,setCityTown]=useState('')
 const [options,setOptions]=useState([])
 const [maxp,setMaxP]=useState('')
 const [maxo,setMaxO]=useState('')
 const [offices ,setOffices]=useState([])
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const notify = (text) => toast.success( text,{position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored", 
  fontFamilly:"Cairo"
});
const notify2 = (text) => toast.error( text,{position: "bottom-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
theme: "colored", 
fontFamilly:"Cairo"
});


  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure(); // First modal
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure(); // Second modal
  const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure(); // Second modal
  const [overlay, setOverlay] = useState(<OverlayOne />)
  const iconColor = useColorModeValue("brand.500", "white");
  useEffect(() => {
    axios.post(`${BASE_URL_2}/users/message/getAll`,null,{
      headers: {
        'Authorization': `${cookies.token}`
      }
    })
    .then((response) => {
      const result = response.data
      
      setOffices(result.messages)
    setLoading(false)
    })

    axios.post(`${BASE_URL_2}/users/get/message/replai`,null,{
      headers: {
        'Authorization': `${cookies.token}`
      }
    })
    .then((response) => {
      const result = response.data
      console.log(result)
      setReplay(result.replai)
    
    })

   
  }, [])

  
  const [name,setName]= useState('')
  const [text,setText]= useState('')
  const [email,setEmail]= useState('')
  const [address,setAddress]= useState('')
  const [phone,setPhone]= useState('')
  const [password,setPassword]= useState('')
 const [type,setType]=useState('')
 const [id_office,setIdOffice]=useState(1)
 const [id_messages,setIdMessage]=useState('')
  const [id_user,setIdUser]= useState('')
  const [notes,setNotes]=useState([])
  const [shared,setShared]=useState(false)
  const[nb_order,setNbOffer]=useState(1)
  const[nb_proposal,setNbProposal]=useState(1)
  const [pricing,setPricing]=useState('')
  const[phone_01,setPhone_01]=useState('')
  const[phone_02,setPhone_02]=useState('')
  const[phone_fixe,setPhone_Fixe]=useState('')
  const [fall_number,setFallNumber]=useState('')
  const [establishment_number,setEstablishmentNumber]=useState('')



const delet = async (id)=>{

  axios.post(`${BASE_URL_2}/users/message/delete`,{id})
  .then((response) => {
    const result = response.data
    if(result.succes){
        axios.post(`${BASE_URL_2}/users/message/getAll`,null,{
          headers: {
            'Authorization': `${cookies.token}`
          }
        })
        .then((response) => {
          const result = response.data
          
          setOffices(result.messages)
        
    
           onClose1()
        notify("تم حذف الرسالة  بنجاح")
      
      })}
  
  
    else{
      onClose1()
      notify2(result.message)
    }
  
  })


}
 
const  repaly=async()=>{
  axios.post(`${BASE_URL_2}/users/admin/message/replai`,{id_messages,text,email})
  .then((response) => {
    const result = response.data
    if(result.succes){
        axios.post(`${BASE_URL_2}/users/message/getAll`,null,{
          headers: {
            'Authorization': `${cookies.token}`
          }
        })
        .then((response) => {
          const result = response.data
          
          setOffices(result.messages)
        
    
           onClose3()
        notify("تم  الرد على الرسالة  بنجاح")
        axios.post(`${BASE_URL_2}/users/get/message/replai`,null,{
          headers: {
            'Authorization': `${cookies.token}`
          }
        })
        .then((response) => {
          const result = response.data
          console.log(result)
          setReplay(result.replai)
        
        })
      
      })}
  
  
    else{
      onClose1()
      notify2(result.message)
    }
  
  })
}
function idMessageExists(idMessage) {
  console.log(idMessage)
  return replay.some(item => item.id_message === idMessage);
}

function repaly_message(idMessage) {
  const foundItem = replay.find(item => item.id_message === idMessage);
  return foundItem ? foundItem.text : null;
}
  
  
return ( <> 
  {loading==true?   <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh', // Set minimum height to fill the viewport

  }}> <ReactLoading  type="spin" color="#422AFB" height={100} width={80} /></div>
 :( 
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <SimpleGrid
      mb='20px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
         <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        > قائمة الرسائل
        </Text>
      </Flex>
      <Table  variant='simple' color='gray.500' mb='24px'>
      <TableContainer>
    <Table variant='simple'>
    <Thead>
        
            <Tr >
        
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                  الاسم
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                  البريد الالكتروني
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                    الموضوع
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                   الرسالة
                </Th>
               
                
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "20px" }}
                    color='gray.400'>
                    
                  </Flex>
                  رد
                </Th>
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "20px" }}
                    color='gray.400'>
                    
                  </Flex>
                  العمليات
                </Th>
            
            </Tr>
         
        </Thead>
      <Tbody>
        {offices.map((item,index)=>{
          return(
            <Tr>

            <Td>{item.name}</Td>
            <Td>{item.email}</Td>
            <Td>{item.subject}</Td>
            <Td>{item.text}</Td>
            <Td > {idMessageExists(item.id_messages )? <button onClick={()=>{ 
              setIdMessage(item.id_messages)
          setText(repaly_message(item.id_messages))
              onOpen2()
             
            }
                }>قراءة  الرد </button> :<button onClick={()=>{ 
              setIdMessage(item.id_messages)
              setEmail(item.email)
         
              onOpen3()
             
            }
                }>رد</button> }
            
           </Td>
         
            <Td >
            <Icon as={AiFillDelete} width='20px' mr='10px' height='20px'  cursor='pointer' color={iconColor}  onClick={()=>delet(item.id_messages)}/></Td>
          </Tr>
          )
        })}  
      </Tbody>
    </Table>
  </TableContainer>
      </Table>
    </Card>
          
    </SimpleGrid>
    <Modal isCentered    isOpen={isOpen3} onClose={onClose3}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader>الرد على الرسالة </ModalHeader>
         
          <ModalCloseButton />
          <ModalBody>
         
        
          <FormControl>
              <FormLabel mt='15px'>  الرسالة</FormLabel>
              <Textarea     onChange={(event)=>{setText(event.target.value)}}/>
            </FormControl>
            
          
          </ModalBody>

          
         
      
          <ModalFooter>
          <Button  disabled={text?false:true} onClick={repaly}  colorScheme='blue' mr={3}  >
              اضافة
            </Button>
            <Button  onClick={onClose3}>اغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isCentered    isOpen={isOpen2} onClose={onClose2}>
        {overlay}
        <ModalContent textAlign="center">
        <ModalHeader>الرد على الرسالة </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
         
          <FormControl>
              <FormLabel mt='15px'> الرد المرسل</FormLabel>
              <Textarea    value={text}/>
            </FormControl>
            
          
          </ModalBody>

          
         
      
          <ModalFooter>
      
            <Button  onClick={onClose2}>اغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ToastContainer  position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"/>
  </Box>
 )}
  </>)
}



