import React,{useState,useEffect} from 'react'
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,Text
} from "@chakra-ui/react";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,

} from 'chart.js';
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import ReactLoading from 'react-loading';
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import axios from 'axios';
import { useCookies } from 'react-cookie';

import {
  HiUsers
  } from "react-icons/hi";
  import {
    BsCartFill,
    } from "react-icons/bs";

import Card from "components/card/Card";

import { TbHomeSearch
  
}  from "react-icons/tb"; 
import { GiOfficeChair } from 'react-icons/gi';
import Chart from 'chart.js/auto';
import { Pie } from 'react-chartjs-2';
import { BASE_URL_2 } from 'config';
export default function UserReports() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const[proposales,setProposales]=useState('')
  const[orders,setOrders]=useState('')
  const[myProposales,setMyProposales]=useState('')
  const[myOrders,setMyorders]=useState('')
  const[users,setUsers]=useState('')
  const [user,setUser]=useState('')
  const [office,setOffice]=useState('')
  const [proposalByMonth,setProposalByMonth]=useState([])
  const [orderByMonth,setOrderByMonth]=useState([])
  const [proposalByTown,setProposalByTown]=useState([])
  const [orderByTown,setOrderByTown]=useState([])
  const [proposalByCity,setProposalByCity]=useState([])
  const [orderByCity,setOrderByCity]=useState([])
  const [town,setTown]=useState([])
  const [city,setCity]=useState([])
 const [userNames,setUserNames]=useState([])
 const [proposalByUser,setProposalByUser]=useState([])
 const [orderByUser,setOrderByUser]=useState([])
 const [type,setType]=useState([])
 const [proposalByType,setProposalByType]=useState([])
 const [orderByType,setOrderByType]=useState([])
 const [offices,setOffices]=useState([])
 const [pricingNames,setPricingNames]=useState([])
 const [proposalByPricing,setProposalByPricing]=useState([])
 const [orderByPricing,setOrderByPricing]=useState([])
 const [officesByPricing,setOfficesByPricing]=useState([])
 const [userByPricing,setUserByPricing]=useState([])
 const [users2,setUsers2]=useState([])
 const [offices2,setOffices2]=useState([])
 const [loading,setLoading]=useState(true) 
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  useEffect(() => {
    const id_office= 1
    axios.post(`${BASE_URL_2}/real_estates/admin/get/price/office`,null,{
      headers: {
        'Authorization': `${cookies.token}`
      }
    })
    .then((response) => {
      const result = response.data
     

      setOffices2(result.office)
      setOfficesByPricing(result.price)
    
    })
    axios.post(`${BASE_URL_2}/real_estates/admin/get/price/user`,null,{
      headers: {
        'Authorization': `${cookies.token}`
      }
    })
    .then((response) => {
      const result = response.data
     
      setUsers2(result.users)
      setUserByPricing(result.price)
   
    
    })
    
      
    axios.post(`${BASE_URL_2}/real_estates/admin/get/type`,null,{
      headers: {
        'Authorization': `${cookies.token}`
      }
    })
    .then((response) => {
      const result = response.data
     
      setProposalByType(result.proposal)
      setOrderByType(result.order)
      setType(result.type)
   
    
    })
    
    
        axios.post(`${BASE_URL_2}/real_estates/admin/order/count`,null,{
          headers: {
            'Authorization': `${cookies.token}`
          }
        })
        .then((response) => {
          const result = response.data
         

          // setProposalByType(result.proposal)
          // setOrderByType(result.order)
          // setType(result.type)
          setOrders(result.count)
       
        
        })
        axios.post(`${BASE_URL_2}/real_estates/admin/proposal/count`,null,{
          headers: {
            'Authorization': `${cookies.token}`
          }
        })
        .then((response) => {
          const result = response.data
         

          // setProposalByType(result.proposal)
          // setOrderByType(result.order)
          // setType(result.type)
          setProposales(result.count)
       
        
        })
        axios.post(`${BASE_URL_2}/users/admin/office/count`,null,{
          headers: {
            'Authorization': `${cookies.token}`
          }
        })
        .then((response) => {
          const result = response.data
         
          setOffices(result.count)
        
        })
        axios.post(`${BASE_URL_2}/users/admin/user/count`,null,{
          headers: {
            'Authorization': `${cookies.token}`
          }
        })
        .then((response) => {
          const result = response.data
         
          setUsers(result.count)
        
        })
        axios.post(`${BASE_URL_2}/real_estates/admin/get/city`,null,{
          headers: {
            'Authorization': `${cookies.token}`
          }
        })
        .then((response) => {
          const result = response.data

          setProposalByCity(result.proposal)
          setOrderByCity(result.order)
          setCity(result.city)
        
        })



        axios.post(`${BASE_URL_2}/real_estates/admin/get/town`,null,{
          headers: {
            'Authorization': `${cookies.token}`
          }
        })
        .then((response) => {
          const result = response.data
        
          setProposalByTown(result.proposal)
          setOrderByTown(result.order)
          setTown(result.town)
        
        })
        axios.post(`${BASE_URL_2}/real_estates/admin/get/monthes`,null,{
          headers: {
            'Authorization': `${cookies.token}`
          }
        })
        .then((response) => {
          const result = response.data
         
          setProposalByMonth(result.proposal)
          setOrderByMonth(result.order)
        
        
        })

        axios.post(`${BASE_URL_2}/real_estates/admin/get/office`,null,{
          headers: {
            'Authorization': `${cookies.token}`
          }
        })
        .then((response) => {
          const result = response.data
          setProposalByUser(result.proposal)
          setOrderByUser(result.order)
          setUserNames(result.user)
         
        
        })
        axios.post(`${BASE_URL_2}/real_estates/admin/get/user`,null,{
          headers: {
            'Authorization': `${cookies.token}`
          }
        })
        .then((response) => {
          const result = response.data
          setProposalByPricing(result.proposal)
          setOrderByPricing(result.order)
          setPricingNames(result.user)
          setLoading(false)
        
        })
          
  
      
  
    
  }, [])
  
  const [cookies, setCookie, removeCookie] = useCookies(['name','pricing','id','id_real_estate','id_office', 'email','token','isOffice','phone','phone_01','phone_2','phone_fixe','fall_number','establishment_number','address']);

  const data1 = {
    labels: ['جانفي', 'فيفري', 'مارس', 'افريل', 'ماي', 'جوان', 'جويلية','اوت','سبتمبر','اكتوبر','نوفمبر','ديسمبر'],
    datasets: [
      {
        label: 'العروض',
        data: proposalByMonth,
        backgroundColor: '#0d6efd',
        borderColor: 'rgba(10, 116, 218, 1)',
      
        borderWidth: 1,
      },
      {
        label: 'االطلبات',
        data: orderByMonth,
        backgroundColor:  '#ff0000',
        borderColor: '#E01C0B',
        borderWidth: 1,
      },
    ],
  };
  const data2 = {
    labels: town,
    datasets: [
      {
        label: 'العروض',
        data: proposalByTown,
        backgroundColor: '#0d6efd',
        borderColor: 'rgba(10, 116, 218, 1)',
      
        borderWidth: 1,
      },
      {
        label: 'االطلبات',
        data: orderByTown,
        backgroundColor:  '#ff0000',
        borderColor: '#E01C0B',
        borderWidth: 1,
      },
    ],
  };

  const data3 = {
    labels: city,
    datasets: [
      {
        label: 'العروض',
        data: proposalByCity,
        backgroundColor: '#0d6efd',
        borderColor: 'rgba(10, 116, 218, 1)',
      
        borderWidth: 1,
      },
      {
        label: 'االطلبات',
        data: orderByCity,
        backgroundColor:  '#ff0000',
        borderColor: '#E01C0B',
        borderWidth: 1,
      },
    ],
  };

  const data7 = {
    labels: pricingNames,
    datasets: [
      {
        label: 'العروض',
        data: proposalByPricing,
        backgroundColor: '#0d6efd',
        borderColor: 'rgba(10, 116, 218, 1)',
      
        borderWidth: 1,
      },
      {
        label: 'االطلبات',
        data: orderByPricing,
        backgroundColor:  '#ff0000',
        borderColor: '#E01C0B',
        borderWidth: 1,
      },
    ],
  };

  const data4 = {
    labels: userNames,
    datasets: [
      {
        label: 'العروض',
        data: proposalByUser,
        backgroundColor: '#0d6efd',
        borderColor: 'rgba(10, 116, 218, 1)',
      
        borderWidth: 1,
      },
      {
        label: 'االطلبات',
        data: orderByUser,
        backgroundColor:  '#ff0000',
        borderColor: '#E01C0B',
        borderWidth: 1,
      },
    ],
  };
  const data8 = {
    labels: officesByPricing,
    datasets: [
      {
        label: 'المكاتب',
        data: offices2,
        backgroundColor: '#0d6efd',
        borderColor: 'rgba(10, 116, 218, 1)',
      
        borderWidth: 1,
      },
  
    ],
  };
  const data9 = {
    labels: userByPricing,
    datasets: [
      {
        label: 'المستخدمين',
        data: users2,
        backgroundColor:  '#ff0000',
        borderColor: '#E01C0B',
        borderWidth: 1,
      },
  
    ],
  };
const data5 = {
    labels: type,
    datasets: [
      {
        label: 'العروض',
        data: proposalByType,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  

  const data6 = {
    labels: type,
    datasets: [
      {
        label: 'االطلبات',
        data: orderByType,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  
  Chart.defaults.font.family = 'Cairo, sans-serif';
  const options2={
   
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                font: {
                    size: 14,
                    family: 'Cairo, sans-serif', 
                },
            },
        },
        title: {
            display: false,
            text: 'Chart.js Bar Chart',
        },
  
      
    },
    width: 200,  // Adjust the width as needed
    height: 200,
  }
  const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            labels: {
                font: {
                    size: 14,
                    family: 'Cairo, sans-serif', 
                },
            },
        },
        title: {
            display: false,
            text: 'Chart.js Bar Chart',
        },
        scales: {
          x: {
              ticks: {
                  font: {
                      family: 'Cairo, sans-serif', 
                  },
              },
          },
          y: {
              ticks: {
                  font: {
                      family: 'Cairo, sans-serif', 
                  },
              },
          },
        }
      
    },
};

  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return ( <> 
    {loading==true?   <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh', // Set minimum height to fill the viewport
  
    }}> <ReactLoading  type="spin" color="#422AFB" height={100} width={80} /></div>
   :( <>
    <Box pt={{ base: "30px", md: "80px", xl: "80px" }}>
      <>
      
       <SimpleGrid
       columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
       gap='20px'
       mb='20px'>
      

       <MiniStatistics
         startContent={
           <IconBox
             w='56px'
             h='56px'
             bg={boxBg}
             icon={
               <Icon w='32px' h='32px' as={GiOfficeChair} color={"#0d6efd"} />
             }
           />
         }
         name='المكاتب'
         value={offices}
       />

       <MiniStatistics
         startContent={
           <IconBox
             w='56px'
             h='56px'
             bg={boxBg}
             icon={
               <Icon w='32px' h='32px' as={HiUsers
               } color={"#ff0000"} />
             }
           />
         }
         name='المستخدمين'
         value={users}
       />
      
     </SimpleGrid>
     
      
      
      </>
     
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={BsCartFill} color={"#0d6efd"} />
              }
            />
          }
          name='  العروض'
          value={proposales}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={TbHomeSearch} color={"#ff0000"} />
              }
            />
          }
          name='الطلبات '
          value={orders}
        />
        
       
      </SimpleGrid>
      <SimpleGrid    columns={{ base: 1, md: 1, lg: 1, "2xl": 6 }}
        gap='20px'
        mb='20px'>
  
        </SimpleGrid>
      
    </Box>
    <Box pt={{ base: "30px", md: "20px", xl: "20px" }}>
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
         توزيع العقارات بحسب اشهر السنة
        </Text>
       
      </Flex>
          <Bar className='plot-font' options={options} data={data1} />
        </Card>
      </Box>
      <Box pt={{ base: "30px", md: "20px", xl: "20px" }}>
        <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
         توزيع العقارات بحسب المدن
        </Text>
       
      </Flex>
          <Bar className='plot-font' options={options} data={data2} />
         
        </Card>
        </Box>
        <Box pt={{ base: "30px", md: "20px", xl: "20px" }}>
        <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
         توزيع العقارات بحسب  الاحياء
        </Text>
       
      </Flex>
          <Bar className='plot-font' options={options} data={data3} />
        </Card>
        </Box>
       
        <Box pt={{ base: "30px", md: "20px", xl: "20px" }}>
         <Card
         direction='column'
         w='100%'
         px='0px'
         overflowX={{ sm: "scroll", lg: "hidden" }}>
         <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <Text
             color={textColor}
             fontSize='22px'
             fontWeight='700'
             lineHeight='100%' 
           >
            توزيع العقارات  بحسب المكاتب
           </Text>
          
         </Flex>
             <Bar className='plot-font' options={options} data={data4} />
            
           </Card>
           </Box>
           <Box pt={{ base: "30px", md: "20px", xl: "20px" }}>
         <Card
         direction='column'
         w='100%'
         px='0px'
         overflowX={{ sm: "scroll", lg: "hidden" }}>
         <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <Text
             color={textColor}
             fontSize='22px'
             fontWeight='700'
             lineHeight='100%' 
           >
            توزيع العقارات  بحسب المستخدمين في الاشتراك التجريبي
           </Text>
        
         </Flex>
             <Bar className='plot-font' options={options} data={data7} />
            
           </Card>
           </Box>
           <Box pt={{ base: "30px", md: "20px", xl: "20px" }}>
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
         توزيع المكاتب بحسب  الباقات
        </Text>
       
      </Flex>
          <Bar className='plot-font' options={options} data={data8} />
        </Card>
      </Box>         <Box pt={{ base: "30px", md: "20px", xl: "20px" }}>
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
         توزيع المستخدمين بحسب  الباقات
        </Text>
       
      </Flex>
          <Bar className='plot-font' options={options} data={data9} />
        </Card>
      </Box>
      <Box pt={{ base: "30px", md: "10px", xl: "20px" }}>
     
       
     
      <Card
   direction='column'
   w='100%'
   px='0px'
   overflowX={{ sm: "scroll", lg: "hidden"  }}
>
<SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 6 }}
        gap='20px'
        mb='20px'>
  
    <Flex flex='1' direction='column' align='center'>
      <Text
        color={textColor}
        fontSize='18px'
        fontWeight='700'
        lineHeight='100%'
      >
        توزيع العروض بحسب انوع العقارات
      </Text>
      <Pie className='plot-size' options={options2} data={data5} />
    </Flex>
    <Flex flex='1' direction='column' align='center'>
      <Text
        color={textColor}
        fontSize='18px'
        fontWeight='700'
        lineHeight='100%'
      >
        توزيع الطلبات بحسب انوع العقارات
      </Text>
      <Pie   className='plot-size' options={options2} data={data6} />
    </Flex>

  </SimpleGrid>
</Card>

      
       </Box>
      
     
 </>)}</> )
}
