// chakra imports
import { Box, Flex, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";

import React from "react";

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;

  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='18px' px="15px" borderRadius='30px'>
      <Brand />
      <Stack direction='column' mb='auto' mt='0px'>
        <Box ps='20px' pe={{ md: "14px", "2xl": "1px" }}>
          <Links routes={routes} />
        </Box>
      </Stack>

     
    </Flex>
  );
}

export default SidebarContent;
