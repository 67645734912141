// Chakra Imports
import {
	Avatar,
	Button,
	Flex,
	Icon,
	Image,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React,{useState,useEffect} from 'react';
import axios from 'axios';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { TbPointFilled } from 'react-icons/tb';
import { io } from 'socket.io-client';

import navImage from 'assets/img/layout/Navbar.png';

import { FaEthereum } from 'react-icons/fa';
import routes from 'routes.js';
import { ThemeEditor } from './ThemeEditor';
import { useCookies } from 'react-cookie';
import { BASE_URL_2 } from 'config';

export default function HeaderLinks(props) {
	// const socket = io('https://back-end.maktabi-0.com/', {
	// 	path: '/socket.io',
	//   });
	const history = useHistory();
	const [cookies, setCookie, removeCookie] = useCookies(['name','id', 'email','token','isOffice','phone','phone_01','phone_2','phone_fixe','fall_number','establishment_number','address']);
	const { secondary } = props;
	const [notification,setNotification]=useState([])
	const navbarIcon = useColorModeValue('gray.400', 'white');
	let menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const textColorBrand = useColorModeValue('brand.700', 'brand.400');
	const ethColor = useColorModeValue('gray.700', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
	const ethBox = useColorModeValue('white', 'navy.800');
	const fetchNotifications = async () => {
		try {
		  const response = await axios.post(`${BASE_URL_2}/users/admin/notification/get`, null, {
			headers: {
			  'Authorization': `${cookies.token}`
			}
		  });
		  const result = response.data;
		  setNotification(result.notification);
		} catch (error) {
		  console.error('Error fetching notifications:', error);
		}
	  };
	
	//   useEffect(() => {
	// 	// Initial fetch
	// 	fetchNotifications();
	
	// 	// Set up polling interval (adjust as needed)
	// 	const intervalId = setInterval(fetchNotifications, 5000);
	
	// 	// Clean up interval when the component unmounts
	// 	return () => clearInterval(intervalId);
	//   }, []);
	
	
const delet = async ()=>{
	axios.post(`${BASE_URL_2}/users/admin/notification/delete`,null,{
		headers: {
		  'Authorization': `${cookies.token}`
		}
	  })
	  .then((response) => {
		const result = response.data
	  
		setNotification([])
	  
	  })
		 
}
	const logout =()=>{

		history.push('/');	
	}
		
	
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');

	return (
		<Flex
			w={{ sm: '100%', md: 'auto' }}
			alignItems="center"
			flexDirection="row"
			bg={menuBg}
			flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
			p="10px"
			borderRadius="30px"
			boxShadow={shadow}>
			
		
			<SidebarResponsive routes={routes} />
			{/* <Menu>
				{notification.length?
				<MenuButton >
					<Icon style={{position:"absolute"}} mr="-12px" as={TbPointFilled}  color={"red"} w="40px" h="30px" mt="-08px"  />
					<Icon   mr="0px" ml="30px" as={MdNotificationsNone} color={navbarIcon} w="40px" h="30px"  />
				</MenuButton>: <MenuButton >
					<Icon   mr="0px" ml="30px" as={MdNotificationsNone} color={navbarIcon} w="40px" h="30px"  />
				</MenuButton>}
				<MenuList
					boxShadow={shadow}
					p="20px"
					borderRadius="20px"
					bg={menuBg}
					border="none"
					mt="22px"
					me={{ base: '30px', md: 'unset' }}
					minW={{ base: 'unset', md: '400px', xl: '450px' }}
					maxW={{ base: '360px', md: 'unset' }}>
					<Flex jusitfy="space-between" w="100%" mb="20px">
						<Text fontSize="md" fontWeight="600" color={textColor}>
							الاشعارات
						</Text>
						<Text fontSize="sm" fontWeight="500" color={textColorBrand} ms="auto" cursor="pointer" onClick={delet}>
							قراءة
						</Text>
					</Flex>
					<Flex flexDirection="column">
						{notification.map((item,index)=>{

						return(<MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} px="0" borderRadius="8px" mb="10px">
						<ItemContent info={item.notification}  />
					</MenuItem>)
						
					})}
					
					</Flex>
				</MenuList>
			</Menu>
		 */}
			<Menu>
				<MenuButton p="0px">
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color="white"
						name={"Admin"}
						bg="#422afb"
						size="sm"
						w="40px"
						h="40px"
					/>
				</MenuButton>
				<MenuList boxShadow={shadow} p="0px" mt="10px" borderRadius="20px" bg={menuBg} border="none">
					<Flex flexDirection="column" p="10px">
							<Link href='/dashboard/admin/profile'><MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius="8px" px="14px">
						<Text fontSize="sm">معلومات الحساب</Text>
							
						</MenuItem></Link>
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color="red.400"
							borderRadius="8px"
							px="14px">
							<Text fontSize="sm" onClick={logout}> تسجيل الخروج</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
