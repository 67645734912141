import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdLock,
  MdOutlineShoppingCart,

} from "react-icons/md";

import {
BiMap


} from "react-icons/bi";

import {
  HiUsers
  } from "react-icons/hi";
import { BsFillBagFill } from "react-icons/bs";
import {
    BsCartFill,
    BsFillBuildingFill
    } from "react-icons/bs";
import { GiModernCity
      } from "react-icons/gi";
import { TbHomeCog,TbHomeSearch
      }  from "react-icons/tb"; 
   
import { GiOfficeChair } from 'react-icons/gi';
import MainDashboard from "views/main/default";
import City from "views/main/city/index";
import Town from "views/main/town/index";
import Type from "views/main/type/index";
import Users from "views/main/users";
import Profile from "views/main/profile";
import Proposales from "views/main/proposales";
import Orders from "views/main/orders";
import { BiDollarCircle } from "react-icons/bi";
import SignInCentered from "views/auth/signIn";
import ProposalAdd from "views/main/proposalAdd";
import OrderAdd from "views/main/orderAdd";
import Map from "views/main/map";
import ProposalDetails from "views/main/proposel-datails";
import OrderDetails from "views/main/order-details";
import PropsalUpdate from "views/main/proposalUpdate";
import OrderUpdate from "views/main/orderUpdate";
import Offices from "views/main/offices";
import Subscriptions from "views/main/Subscriptions";
import { BiMessageRoundedDetail } from "react-icons/bi";
import Messages from "views/main/messages";
import Pricing from "views/main/pricing";
const routes = [
  {
    name: "الرئيسية",
    layout:"/dashboard",
    path:"/admin/home",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
    all:true,
  },
  {
    name: "الباقات",
    layout:"/dashboard",
    path:"/admin/pricing",
    icon: <Icon as={BiDollarCircle} width='20px' height='20px' color='inherit' />,
    component: Pricing,
    all:true,
  },
  {
    name: "الحساب",
    layout:"/dashboard",
    path:"/admin/profile",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: Profile,
    all:true,
  },
  {
    name: "المستخدمين",
    category:true,
    items:[
      {name: "طلبات الاشتراك",
      layout:"/dashboard",
      path: "/admin/subscription",
      all:true,
      icon: <Icon as={BsFillBagFill} width='20px' height='20px' color='inherit'/>,
      component: Subscriptions},
      {name: "اصحاب المكاتب",
      layout:"/dashboard",
      path: "/admin/offices",
      all:true,
      icon: (
        <Icon
          as={GiOfficeChair}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),
      component:Offices
      },
      {name: "المستخدمين",
      layout:"/dashboard",
      path: "/admin/users",
      all:true,
      icon: (
        <Icon
          as={HiUsers}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),
      component:Users

      },
      {name: "الرسائل",
      layout:"/dashboard",
      path: "/admin/messages",
      all:true,
      icon: (
        <Icon
          as={BiMessageRoundedDetail}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),
      component:Messages

      }
    ]
  },
  {
    name: "الاعدادات",
    category:true,
    items:[
      {name: "اضافة مدينة",
      layout:"/dashboard",
      path: "/admin/town",
      all:true,
      icon: <Icon as={GiModernCity} width='20px' height='20px' color='inherit'/>,
      component: Town},
      {name: "اضافة حي",
      layout:"/dashboard",
      path: "/city",
      all:true,
      icon: (
        <Icon
          as={BsFillBuildingFill}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),
      component:City
      },
      {name: "اضافة نوع عقار",
      layout:"/dashboard",
      path: "/admin/type",
      all:true,
      icon: (
        <Icon
          as={TbHomeCog}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),
      component:Type

      }
    ]
  },
  {
    name: "العقارات",
    category:"true",
    items:[
      {name: "خريطة العقارات",
      layout:"/dashboard",
      path: "/admin/map",
      all:true,
      icon: (
        <Icon
          as={BiMap}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),
      component:Map
      },
      {name: "العروض",
      layout:"/dashboard",
      path: "/admin/proposales",
      all:true,
      component: Proposales,
      icon: (
        <Icon
          as={BsCartFill}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),

      },

      {name: "الطلبات",
      layout:"/dashboard",
      path: "/admin/orders",
      pricing:true,
      all:true,
      icon: (
        <Icon
          as={TbHomeSearch}
          width='20px'
          height='20px'
          color='inherit'
        />
      ),
      component: Orders,
      },

   
    ]
  }, 
  {
    name: "Sign In",
    layout: "/",
    path: "/",
    all:true,
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
   
  
  },

 


  {
    name: "اضافة عرض",
    layout: "/dashboard",
    all:true,
    path: "/admin/proposals/add",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ProposalAdd,
   
  
  },
  {
    name: "اضافة طلب",
    layout: "/dashboard",
    all:true,
    path: "/admin/order/add",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: OrderAdd,
   
  
  },
  {
    name: "تفاصيل العرض",
    layout: "/dashboard",

    path: "/admin/proposal/details",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ProposalDetails,
   
  
  },
  {
    name: "تفاصيل الطلب",
    layout: "/dashboard",
 
    path: "/admin/order/details",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: OrderDetails,
   
  
  },
  {
    name: " تعديل العرض",
    layout: "/dashboard",

    path: "/admin/proposal/update",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: PropsalUpdate,
   
  
  },
  {
    name: " تعديل الطلب",
    layout: "/dashboard",

    path: "/admin/order/update",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: OrderUpdate,
   
  
  },

];

export default routes;
