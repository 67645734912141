import React,{useState,useEffect} from 'react'

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Box,SimpleGrid,Flex,Text, useColorModeValue,Icon,Button  ,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,FormControl,FormLabel,Input,
} from '@chakra-ui/react'
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useToast } from '@chakra-ui/react'
import axios from 'axios';
import { IoIosAddCircle } from 'react-icons/io';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from "components/card/Card";
import { MdOutlineEdit } from 'react-icons/md';
import { AiFillDelete } from 'react-icons/ai';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { BASE_URL_2 } from 'config';
export default function Town  ()  {
  const [loading,setLoading]=useState(true) 
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )
 const [town,setTown]=useState([])
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const notify = (text) => toast.success( text,{position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored", 
  fontFamilly:"Cairo"
});
const notify2 = (text) => toast.error( text,{position: "bottom-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
theme: "colored", 
fontFamilly:"Cairo"
});


  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure(); // First modal
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure(); // Second modal
  const [overlay, setOverlay] = useState(<OverlayOne />)
  const iconColor = useColorModeValue("brand.500", "white");
  useEffect(() => {
    axios.post(`${BASE_URL_2}/type/getAll`)
      .then((response) => {
        const result = response.data
    
        setTown(result.types)
        setLoading(false)
      })
  
    
  }, [])
  const [name,setName]= useState('')
  const [longitude,setLongitude]= useState('')
  const [latitude,setLatitude]= useState('')
  const [id_real_estate_type,setIdTown]= useState('')
  const add = async()=>{
  axios.post(`${BASE_URL_2}/type/add`,{name})
  .then((response) => {
    const result = response.data
    if(result.succues){

      axios.post(`${BASE_URL_2}/type/getAll`)
      .then((response) => {
        const result = response.data
      
        setTown(result.types)
        onClose1()
        notify("تم اضافة نوع جديد بنجاح")
      
      })
  
    }
    else{
      onClose1()
      notify2(result.message)
    }
  
  })

   
}
const delet = async (id_real_estate_type)=>{
  axios.post(`${BASE_URL_2}/type/delete`,{id_real_estate_type})
  .then((response) => {
    const result = response.data
    if(result.succes){

      axios.post(`${BASE_URL_2}/type/getAll`)
      .then((response) => {
        const result = response.data
      
        setTown(result.types)
        onClose1()
        notify("تم حذف المدينة  بنجاح")
      
      })
  
    }
    else{
      onClose1()
      notify2(result.message)
    }
  
  })


}


const update = async ()=>{

  axios.post(`${BASE_URL_2}/type/update`,{name,id_real_estate_type})
  .then((response) => {
    const result = response.data
    if(result.succes){

      axios.post(`${BASE_URL_2}/type/getAll`)
      .then((response) => {
        const result = response.data
      
        setTown(result.types)
        onClose2()
        notify("تم تعديل معلومات المدينة  بنجاح")
      
      })
  
    }
    else{
      onClose2()
      notify2(result.message)
    }
  
  })


}
  
  
return ( <> 
  {loading==true?   <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh', // Set minimum height to fill the viewport

  }}> <ReactLoading  type="spin" color="#422AFB" height={100} width={80} /></div>
 :( 
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <SimpleGrid
      mb='20px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
         <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
         انواع العقارات المتاحة
        </Text>
       <Button  
       backgroundColor={bgButton}  
        borderRadius='10px'   
        color={iconColor}  
        onClick={()=>{
        setOverlay(<OverlayOne />)
        onOpen1()
        }}
      
        >اضافة نوع  <Icon as={IoIosAddCircle} width='20px' mr='5px' height='20px' color={iconColor} /></Button>
      </Flex>
      <Table  variant='simple' color='gray.500' mb='24px'>
      <TableContainer>
    <Table variant='simple'>
    <Thead>
        
            <Tr >
             
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    
                  </Flex>
                  النوع 
                </Th>
               
                
                <Th
                  pe='10px'
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "20px" }}
                    color='gray.400'>
                    
                  </Flex>
                  العمليات
                </Th>
            
            </Tr>
         
        </Thead>
      <Tbody>
        {town.map((item,index)=>{
        
          return(
            <Tr>
            <Td>{item.name}</Td>
  
            <Td ><Icon as={MdOutlineEdit} width='20px' mr='10px' height='20px' cursor='pointer' color={iconColor}  onClick={()=>{
              setName(item.name)
              setIdTown(item.id_real_estate_type)
         
              onOpen2()
            }}  /> 
            
            <Icon as={AiFillDelete} width='20px' mr='10px' height='20px'  cursor='pointer' color={iconColor}  onClick={()=>delet(item.id_real_estate_type)}/></Td>
          </Tr>
          )
        })}  
      </Tbody>
    </Table>
  </TableContainer>
      </Table>
    </Card>
          
    </SimpleGrid>
    <Modal isCentered    isOpen={isOpen1} onClose={onClose1}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader> ادخل معلومات  نوع العقار</ModalHeader>
         
          <ModalCloseButton />
          <ModalBody>
          
          <FormControl>
              <FormLabel> النوع</FormLabel>
              <Input onChange={(event)=>{setName(event.target.value)}}/>
            </FormControl>

        
          </ModalBody>

          
         
      
          <ModalFooter>
          <Button  disabled={name?false:true}  colorScheme='blue' mr={3}  onClick={add}>
              اضافة
            </Button>
            <Button  onClick={onClose1}>اغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isCentered    isOpen={isOpen2} onClose={onClose2}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader> تعديل معلومات نوع العقار </ModalHeader>
         
          <ModalCloseButton />
          <ModalBody>
          
          <FormControl>
              <FormLabel> النوع</FormLabel>
              <Input  value={name}  onChange={(event)=>{setName(event.target.value)}}/>
            </FormControl>

            
          </ModalBody>

          
         
      
          <ModalFooter>
          <Button   disabled={name?false:true}  colorScheme='blue' mr={3}  onClick={update}>
              تعديل
            </Button>
            <Button  onClick={onClose2}>اغلاق</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ToastContainer  position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"/>
  </Box>

  )}</>)
}



