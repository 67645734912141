import React,{useState,useEffect} from 'react'

import {
  Box,SimpleGrid,Flex,Text, useColorModeValue,Icon,Button  ,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  InputLeftAddon,InputGroup,
  ModalCloseButton,FormLabel,Input,Link
} from '@chakra-ui/react'
import {DropzoneArea} from 'material-ui-dropzone'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useCookies } from 'react-cookie';
import { MdOutlineEdit } from 'react-icons/md';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { IoIosAddCircle } from 'react-icons/io';
import { NumericFormat } from 'react-number-format';
import { useDisclosure } from '@chakra-ui/hooks';
import 'react-international-phone/style.css';
import Card from "components/card/Card";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from '../proposalAdd/firebaseConfig';
import { MdOutlineFileDownloadDone} from 'react-icons/md'
import L from 'leaflet';
import ReactLoading from 'react-loading';
import { BASE_URL_2 } from 'config';
export default function  ProposalUpdate ()  {
  const [loading,setLoading]=useState(true) 
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
  )
  const customMarkerIcon2 = new L.Icon({
    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41]
  });



const iconColor = useColorModeValue("brand.500", "white");
const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = useState(<OverlayOne />)
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const [cookies, setCookie, removeCookie] = useCookies(['name','id','type','id_office','password', 'email','token','isOffice','phone','phone_01','phone_02','phone_fixe','fall_number','establishment_number','address']);
   const [users,setUsers]=useState([])
   const [user_type,setUsrType]=useState('')
   const[types,setTypes]=useState([])
   const [towns,setTowns]=useState([])
   const [citys,setCitys]=useState([])
   const [town,setTown]=useState('')
    const [images,setImages]=useState([])
   const[show,setShow]=useState(false)
   const [vedios,setVeduis]=useState([])
   const[id_user,setIdUser]=useState(cookies.id)
   const[user_name,setUserName]=useState(cookies.user_name)
   const[user_phone,setPhone]=useState(cookies.phone)
   const [categorie,setCategorie]=useState('')
   const [email,setEmail]=useState(cookies.email)
   const [type,setType]=useState('')
   const [status,setStatus]=useState('')
   const[age,setAge]=useState('')
   const [city,setCity]=useState('')
   const [landline_number,setLandline]=useState('')
   const [surface,setSurface]=useState('')
   const [plan_number,setPlan_number]=useState('')
   const [plan_name,setPlanName]=useState('')
   const [facade,setFacade]=useState('')
   const [street_width,setStreetWidth]=useState('')
   const [price_per_meter,setPricePerMeter]=useState('')
   const [height,setHeigt]=useState('')
   const [width,setWidth]=useState('')
   const [high,setHigh]=useState('')
   const [floors_number,setFloors]=useState('')
   const [apartments_number,setApartments]=useState('')
   const [rooms_number,setRooms]=useState('')
   const [bathrooms_number,setBathrooms]=useState('')
   const[price,setPrice]=useState('')
   const[min_price,setMinPrice]=useState('')
   const[max_price,setMaxPrice]=useState('')
   const [message,setMessage]=useState('')
   const [proposal,setProposal]=useState('')
   const [fall_number,setFallNumber]=useState('')
    useEffect(() => {
     
        const id_office= cookies.id_office
        axios.post(`${BASE_URL_2}/users/user/getAllByOffice`,{id_office})
          .then((response) => {
            const result = response.data
           
            setUsers(result.users)
          
          })
          axios.post(`${BASE_URL_2}/type/getAll`)
          .then((response) => {
            const result = response.data
           
            setTypes(result.types)

       
      axios.post(`${BASE_URL_2}/town/getAll`)
      .then((response) => {
        const result = response.data
       
        setTowns(result.towns)
      
      })
          
          })
          const id_real_estate=cookies.id_real_estate
          axios.post(`${BASE_URL_2}/real_estates/getById`,{id_real_estate})
          .then((response) => {
            const result = response.data
           
            setProposal(result.real_estate)
            setUserName(result.real_estate.user_name)
            setUsrType(result.real_estate.user_type)
            setTown(result.real_estate.town)
            setCity(result.real_estate.city)
            setType(result.real_estate.type)
            setCategorie(result.real_estate.categorie)
            setStatus(result.real_estate.status)
            setPrice(result.real_estate.price)
            setSurface(result.real_estate.surface)
            setAge(result.real_estate.age)
            setLandline(result.real_estate.landline_number)
            setPlan_number(result.real_estate.plan_number)
            setPlanName(result.real_estate.plan_name)
            setApartments(result.real_estate.apartments_number)
            setBathrooms(result.real_estate.bathrooms_number)
            setStreetWidth(result.real_estate.street_width)
            setFacade(result.real_estate.facade)
            setWidth(result.real_estate.width)
            setHeigt(result.real_estate.height)
            setHeigt(result.real_estate.high)
            setLatitude(result.real_estate.latitude)
            setLongitude(result.real_estate.longitude)
            setFloors(result.real_estate.floors_number)
            setPricePerMeter(result.real_estate.price_per_meter)
            setFallNumber(result.real_estate.fall_number)
            const town=result.real_estate.town
                axios.post(`${BASE_URL_2}/city/getByTown`,{town})
                .then((response) => {
                  const result = response.data
                 
                  setCitys(result.city)
                  setLoading(false)
                })
          
          })
          
    
    
    }, [])

  
    useEffect(() => {
        axios.post(`${BASE_URL_2}/city/getByTown`,{town})
        .then((response) => {
          const result = response.data
         
          setCitys(result.city)
        
        })
    
    }, [town])

    const [position, setPosition] = useState([24.7136,46.6753]); // Default position
    const [latitude, setLatitude] = useState(24.7136);
    const [longitude, setLongitude] = useState(46.6753);
    const [imageUrls, setImageUrls] = useState([]);
    const [videoUrls, setVideoUrls] = useState([]);
    const [progresspercent, setProgresspercent] = useState(0);
    const handleSubmit = async (e) => {
      setLoading(true)
      e.preventDefault();
    
      const uploadFile = async (file, storagePath) => {
        const storageRef = ref(storage, storagePath);
        const uploadTask = uploadBytesResumable(storageRef, file);
        return new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              setProgresspercent(progress);
            },
            (error) => {
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            }
          );
        });
      };
    
      try {
        const uploadedImagesUrls = await Promise.all(
          images.map((file) => uploadFile(file, `images/${file.name}`))
        );
    
        const uploadedVideosUrls = await Promise.all(
          vedios.map((file) => uploadFile(file, `videos/${file.name}`))
        );
    
        // API Call to save data
        const id_office = cookies.id_office;
        const id_real_estate=proposal.id_real_estate
   
        const response = await axios.post(`${BASE_URL_2}/real_estate/update`,{id_real_estate, categorie, type, status, age, town,
        city, landline_number, surface, plan_name, plan_number, facade, street_width,
        price_per_meter, width, height, floors_number, apartments_number, rooms_number,
        bathrooms_number, price, min_price, max_price, longitude, latitude,user_type,fall_number});
    
        const result = response.data;
        const id =proposal.id_real_estate
     
    

        if (images.length) {
          await axios.post(`${BASE_URL_2}/real_estates/file/delete`, { id, type: "image" });
        
          await Promise.all(
            uploadedImagesUrls.map((url) =>
              axios.post(`${BASE_URL_2}/real_estates/file/add`, { id, type: "image", url })
            )
          );
        }
        
        if (vedios.length) {
          await axios.post(`${BASE_URL_2}/real_estates/file/delete`, { id, type: "video" });
        
          await Promise.all(
            uploadedVideosUrls.map((url) =>
              axios.post(`${BASE_URL_2}/real_estates/file/add`, { id, type: "video", url })
            )
          );
        }
        setLoading(false)
        setMessage('تم  تعديل  العرض بنجاح');
        setOverlay(<OverlayOne />);
        onOpen();
        
      } catch (error) {
        // Handle errors
        console.error(error);
      }
    };
    
    const handleSubmit3 = (e) => {
      e.preventDefault();
      const uploadedUrls = [];
      const uploadedVedios = [];
    
      const uploadImagePromises = images.map((file) => {
      
        const storageRef = ref(storage, `images/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        return new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgresspercent(progress);
            },
            (error) => {
              alert(error);
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                uploadedUrls.push(downloadURL);
                if (uploadedUrls.length === images.length) {
                  setImageUrls(uploadedUrls);
               
                  resolve();
                }
              });
            }
          );
        });
      });
    
      const uploadVideoPromises = vedios.map((file) => {
        const storageRef = ref(storage, `vedios/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
    
        return new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgresspercent(progress);
            },
            (error) => {
              alert(error);
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                uploadedVedios.push(downloadURL);
                if (uploadedVedios.length === vedios.length) {
                  setVideoUrls(uploadedVedios);
                  resolve();
                }
              });
            }
          );
        });
      });
    
      Promise.all([...uploadImagePromises, ...uploadVideoPromises])
        .then(() => {
          const id_office = cookies.id_office;
        
          axios.post(`${BASE_URL_2}/real_estates/proposal/add`, {id_user,user_name,email,user_phone,id_office, categorie, type, status, age, town,
            city, landline_number, surface, plan_name, plan_number, facade, street_width,
            price_per_meter, width, height,high, floors_number, apartments_number, rooms_number,
            bathrooms_number, price, min_price, max_price, longitude, latitude,user_type})
            .then((response) => {
              const result = response.data;
              const id = result.id;
    
              imageUrls.forEach((url) => {
                const type = "image";
                axios
                  .post(`${BASE_URL_2}/real_estates/file/add`, { id, type, url })
                  .then((response) => {
                    const result = response.data;
                   ;
                  });
              });
              
              videoUrls.forEach((url) => {
                const type = "video";
                axios
                  .post(`${BASE_URL_2}/real_estates/file/add`, { id, type, url })
                  .then((response) => {
                    const result = response.data;
                   ;
                  });
              });
             
              setMessage('تم تعديل العرض بنجاح')
              setOverlay(<OverlayOne />)
              onOpen()
            });
        })
        .catch((error) => {
          // Handle errors
          console.error(error);
         });
    };
    

    
    const handleMarkerDragEnd = (e) => {
        const marker = e.target;
        const markerPosition = marker.getLatLng();
      
        setLatitude(markerPosition.lat);
        setLongitude(markerPosition.lng);
      };
    
    
  

  const handleChange1 = (newFiles) => {
    setImages(newFiles);
  };
  const handleChange2 = (newFiles) => {
setVeduis(newFiles)  };
  const handleLatitudeChange = () => {
    
    setPosition([latitude, longitude]);
  };

  const handleLongitudeChange = (e) => {
    
    setPosition([latitude, longitude]);
  };



    
  return ( <> 
    {loading==true?   <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh', // Set minimum height to fill the viewport
  
    }}> <ReactLoading  type="spin" color="#422AFB" height={100} width={80} /></div>
   :( 
    <>
       <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <SimpleGrid
      mb='20px'
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
         <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
     
        <Flex px='25px' justify='space-between' mb='50px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
         ادخل معلومات العقار
        </Text>
        <Button  
       isDisabled={user_name&&user_type&&fall_number&&city&&town&&type&&categorie&&status&&age&&price?false:true}
       backgroundColor={bgButton}  
        borderRadius='10px'   
        color={iconColor}  
        type='submit'
        onClick={handleSubmit}
      
        >  تعديل العرض <Icon as={MdOutlineEdit} width='20px' mr='5px' height='20px' color={iconColor} /></Button>
       </Flex>
        <SimpleGrid columns={{sm:1, md:2}} spacing={10}>
    
        
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> نوع المستخدم</FormLabel>
        <Select  value={user_type} width='400px' placeholder='اختر  نوع المستخدم' onChange={(e)=>{setUsrType(e.target.value)}} >
               
           
                  <option value='مالك' >مالك</option>
                  <option value='مسوق' >مسوق</option>
                  
               
        </Select>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>  نوع العقار</FormLabel>
        <Select  value={type} width='400px' placeholder='اختر نوع العقار' onChange={(e)=>{setType(e.target.value)}} >
                {types.map((item)=>{
                  return(
                  <option value={item.name} >{item.name}</option>
                  )
                })}
        </Select>

        </Flex>

        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>   المدينة</FormLabel>
        <Select  value={town} width='400px' placeholder='اختر  المدينة' onChange={(e)=>{
            setTown(e.target.value)

        }} >
                {towns.map((item)=>{
                  return(
                  <option value={item.name} >{item.name}</option>
                  )
                })}
        </Select>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> الحي</FormLabel>
        <Select   value={city} width='400px' placeholder='اختر  الحي' onChange={(e)=>{setCity(e.target.value)}} >
                {citys.map((item)=>{
                  return(
                  <option value={item.name} >{item.name}</option>
                  )
                })}
        </Select>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> نوع العرض</FormLabel>
        <Select  value={categorie} onChange={(e)=>{setCategorie(e.target.value)}}  width='400px' placeholder='اختر  نوع العرض'  >
            
                  <option value='استثمار' >استثمار</option>
                  <option value='ايجار' >ايجار</option>
                  <option value='بيع' >بيع</option>
             
        </Select>

        </Flex>

        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>  حالة العقار</FormLabel>
        <Select  value={status} onChange={(e)=>{setStatus(e.target.value)}}  width='400px' placeholder='اختر  حاله العرض'  >
            
                  <option value='جديد' >جديد</option>
                  <option value='مستعمل' >مستعمل</option>
                
             
        </Select>

        </Flex>

        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >   مساحة العقار</FormLabel>
           <InputGroup width='400px'>
          <InputLeftAddon children='متر²' />
          <Input    value={surface} onChange={(e)=>{setSurface(e.target.value)}}    ></Input>
        </InputGroup>
        

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>   سعر العقار</FormLabel>
        
          <InputGroup width='400px'>
          <InputLeftAddon children='ر.س' />
          <NumericFormat
          value={price}
        thousandSeparator='.' decimalSeparator=','
        style={{ marginLeft: '5px' }}
        customInput={Input}
        onValueChange={(values, sourceInfo) => {
        setPrice(values.formattedValue)
        }}
      />
        </InputGroup>
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'  >   عمر العقار</FormLabel>
      
          <InputGroup width='400px'>
          <InputLeftAddon children='سنة' />
          <Input value={age}  onChange={(e)=>{setAge(e.target.value)}} ></Input>
        </InputGroup>
          
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'  > رقم رخصة الاعلان</FormLabel>
      
      
          <Input width='400px' value={fall_number} onChange={(e)=>{setFallNumber(e.target.value)}} ></Input>
  
          
        </Flex>
       
        {
          show==false&& (
          <Flex px='25px' justify='space-between' mb='20px' mt='20px'   align='center'>
                  <Button  
                  padding='10px 50px'
                backgroundColor={bgButton}  
                  borderRadius='10px'   
                  color={iconColor}  
                  onClick={()=>{
                    setShow(true)
                  }}
                
                  >  عرض الحقول الاختيارية  </Button></Flex>
          )
        }
       
        { show==true&&(
          <>
           <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>رقم الأرض</FormLabel>
          <Input value={landline_number} width='400px'  onChange={(e)=>{setLandline(e.target.value)}}></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> رقم المخطط</FormLabel>
          <Input value={plan_number}  onChange={(e)=>{setPlan_number(e.target.value)}}  width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> اسم المخطط </FormLabel>
          <Input value={plan_name}  onChange={(e)=>{setPlanName(e.target.value)}}  width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>  الواجهة </FormLabel>
          <Input  value={facade} onChange={(e)=>{setFacade(e.target.value)}} width='400px'></Input>
          </Flex>
          <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>  سعر المتر  </FormLabel>
          <InputGroup width='400px'>
          <InputLeftAddon children='ر.س' />
          <Input value={price_per_meter} onChange={(e)=>{setPricePerMeter(e.target.value)}}></Input>
        </InputGroup>

        
        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >عرض الشارع </FormLabel>
          <Input  value={street_width} onChange={(e)=>{setStreetWidth(e.target.value)}} width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >طول العقار </FormLabel>
          <Input value={height} onChange={(e)=>{setHeigt(e.target.value)}}  width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >عرض العقار </FormLabel>
          <Input value={width} onChange={(e)=>{setWidth(e.target.value)}} width='400px'></Input>

        </Flex>

        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >ارتفاع العقار </FormLabel>
          <Input value={high} onChange={(e)=>{setHigh(e.target.value)}}  width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >عدد الأدوار </FormLabel>
          <Input value={floors_number} onChange={(e)=>{setFloors(e.target.value)}} width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>عدد الشقق </FormLabel>
          <Input   value={apartments_number} onChange={(e)=>{setApartments(e.target.value)}} width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >عدد الغرف</FormLabel>
          <Input value={rooms_number} onChange={(e)=>{setRooms(e.target.value)}} width='400px'></Input>

        </Flex>
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600' >عدد دورات المياة</FormLabel>
          <Input value={bathrooms_number} onChange={(e)=>{setBathrooms(e.target.value)}}  width='400px'></Input>

        </Flex>
          </>
        )

        }
       
       
       
         
        </SimpleGrid>
       
        </Card>
        <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>

<Flex px='25px' justify='space-between' mb='50px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
           اختر موقع العقار
        </Text>
       </Flex>
       <SimpleGrid columns={{sm:1, md:2}} spacing={10}>

       <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> الاحداثية الطولية</FormLabel>
          <Input  value={latitude} onChange={(e)=>{setLatitude(e.target.value)
        handleLatitudeChange()}} width='400px' ></Input>

        </Flex>
       
        <Flex px='25px' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>الاحداثية العرضية</FormLabel>
          <Input  value={longitude} onChange={(e)=>{setLongitude(e.target.value)
        handleLongitudeChange()}} width='400px'></Input>

        </Flex>
       </SimpleGrid>
       <MapContainer   center={[latitude, longitude]}  zoom = '8' style={{ height: '400px', width: '100%' }}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker icon={customMarkerIcon2} position={[latitude, longitude]} draggable={true} eventHandlers={{ dragend: handleMarkerDragEnd }}>
          <Popup> (موقع العقار: {latitude} , {longitude})</Popup>
        </Marker>
      </MapContainer>
      </Card>
     

      <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>

<Flex px='25px' justify='space-between' mb='50px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%' 
        >
           اختر صور و  فيديوهات
        </Text>
       </Flex>
       <SimpleGrid columns={{sm:1, md:2}} spacing={10}>

       <Flex px='25px' flexDirection='column' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'>   اختر الصور الجديدة </FormLabel>
           <DropzoneArea
            dropzoneText="قم برفع صور العقار"
            acceptedFiles={['image/*']}
            showAlerts={false}
            filesLimit={10}
            onChange={handleChange1}
       
        />
          
        </Flex>
       
        <Flex px='25px' flexDirection='column' justify='space-between' mb='20px' align='center'>
           <FormLabel fontWeight='600'> اختر الفيديوهات الجديدة </FormLabel>
           <DropzoneArea
            dropzoneText="قم برفع فيديوهات العقار"
            acceptedFiles={['video/*']}
            showAlerts={false}
            filesLimit={10}
            onChange={handleChange2}
       
        />
          
        </Flex>
       </SimpleGrid>
    
      </Card>
        </SimpleGrid>
        </Box>
       
   
        <Modal closeOnOverlayClick={false}  isCentered    isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent textAlign="center">
          <ModalHeader>عملية ناجحة</ModalHeader>
          <Icon as={MdOutlineFileDownloadDone} width='50px' height='50px' color='green' margin="auto" />
          <ModalCloseButton />
          <ModalBody>
            <Text>{message}</Text>
          </ModalBody>
          <ModalFooter>
            <Link href='/dashboard/admin/proposales'>
            <Button  onClick={onClose}>اغلاق</Button></Link>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>)}</>
  )
}

